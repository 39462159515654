import React from 'react';
import Main from './components/Layout/Main';
import Login from './components/Auth/Login';
import TeamList from './components/Teams/List';
import BrandsList from './components/Brands/List';
import AssetsList from './components/Assets/List';
import VendorList from './components/Vendors/List';
import TicketList from './components/Vnd/TktList';
import CompanyList from './components/Companies/List';
import TcktDetails from './components/Vnd/TktDetails';
import AdminList from './components/Companies/Admins/List';
import OutletList from './components/Companies/Outlet/List';
import TicketDetails from './components/Vendors/TicketDetails';
import ConfirmationProvider from './utils/ConfirmationProvider';
import TicketRequests from './components/Vendors/TicketRequests';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <ConfirmationProvider>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login" component={Login} />
          <Main>
            <Route exact path="/brands" component={BrandsList} />
            <Route exact path="/brands/assets" component={AssetsList} />
            <Route exact path="/companies" component={CompanyList} />
            <Route exact path="/companies/outlet" component={OutletList} />
            <Route exact path="/companies/outlet/admin" component={AdminList} />
            <Route exact path="/vendors" component={VendorList} />
            <Route exact path="/vendors/tickets" component={TicketRequests} />
            <Route exact path="/vendors/ticket/details" component={TicketDetails} />
            <Route exact path='/vnd/tkt/vendorCode=:vndrCode' component={TicketList} />
            <Route exact path="/vnd/rqt/:code" component={TcktDetails} />
            <Route exact path="/teams" component={TeamList} />

          </Main>
        </Switch>
      </ConfirmationProvider>
    </BrowserRouter >
  );
}

export default App;