import React from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../Modal";

export default class AssetDetails extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showAssetDetail}>
                <ModalHeader>
                    <h4 className="m-0">Add Asset Details</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showModal(false, 'AssetDetail')}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <form style={{ width: 500 }}>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="assetDetailType">
                                Asset Type
                            </label>
                            <select
                                id="assetDetailType"
                                name="assetDetailType"
                                className="form-select"
                                value={this.props.detailsData.assetDetailType}
                                onChange={e => this.props.handleChange(e, 'detailsData')}
                            >
                                <option value=''>Select asset type</option>
                                <option value='VIDEOS'>Videos</option>
                                <option value='BROCHURE'>Brochures</option>
                                <option value='MANUAL'>Manuals</option>
                            </select>
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="link">
                                    Link
                                </label>
                            </div>
                            <input
                                id="link"
                                name="link"
                                type="text"
                                className="form-control"
                                value={this.props.detailsData.link}
                                placeholder="Enter link"
                                onChange={e => this.props.handleChange(e, 'detailsData')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="content">
                                    Content
                                </label>
                            </div>
                            <textarea
                                id="content"
                                name="content"
                                type="text"
                                className="form-control"
                                value={this.props.detailsData.content}
                                placeholder="Enter content"
                                onChange={e => this.props.handleChange(e, 'detailsData')}
                            />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="button-outlined"
                        onClick={(e) => this.props.handleSubmit(e, 'assetDetails')}
                    >
                        Submit
                    </button>
                </ModalFooter>
            </Modal >
        )
    }
}