import Add from "./Add";
import React from "react";
import axios from "axios";
import Edit from "./Edit";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../../utils/ConfirmationModal";
import { ConfirmContext } from "../../utils/ConfirmationProvider";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Tooltip } from "react-tooltip";

export default class List extends React.Component {
    static contextType = ConfirmContext;
    state = {
        data: {
            companyName: "",
            domainName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            zipCode: "",
            stateCode: "",
            companyCode: '',
            isActive: true
        },
        // Handling modals
        showAdd: false,
        showEdit: false,
        companyList: [],
        // Handling Pagination 
        totalCount: 0,
        currentPage: 0,
        perPage: 10
    }

    handleChange(e, objName) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [objName]: {
                ...this.state[objName],
                [name]: value
            }
        })
    }

    showModal = (val, name) => {
        this.setState({
            ...this.state,
            [`show${name}`]: val,
            data: val ? this.state.data : {
                ...this.state.data,
                companyName: "",
                domainName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                zipCode: "",
                stateCode: "",
            }
        })
    }

    handlePageChange = (page) => {
        this.setState({
            ...this.state,
            currentPage: page - 1
        }, () => {
            this.getCompanyList();
        })
    }

    getCompanyList() {
        axios({
            url: `/api/company/list?isActive=true&page=${this.state.currentPage}&size=${this.state.perPage}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        }).then((response) => {
            let data = response.data;
            if (data.responseCode === '1000') {
                this.setState({
                    ...this.state,
                    companyList: data.data.companyList,
                    totalCount: data.data.totalCount
                })
            } else {
                toast.error(data.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        })
    }

    async handleDelete(item) {
        const { getConfirmation } = this.context;
        const confirmed = await getConfirmation({
            message: 'Do you really want to delete?'
        });
        if (confirmed) {
            axios({
                url: '/api/company/update',
                data: {
                    ...item,
                    isActive: false
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            }).then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        data: this.state.data,
                        currentPage: this.state.companyList.length === 1 ? this.state.currentPage - 1 : this.state.currentPage
                    }, () => {
                        this.getCompanyList();
                        toast.success('Company deleted sucessfully.', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                        });
                    })
                }
            });
        }
    }

    handleSubmit = (e, type) => {
        e.preventDefault();
        if (type === 'add') {
            const {
                companyName, domainName, addressLine1,
                addressLine2, city, zipCode, stateCode
            } = this.state.data;
            axios({
                url: '/api/company/add',
                data: {
                    companyName: companyName, domainName: domainName, addressLine1: addressLine1,
                    addressLine2: addressLine2, city: city, zipCode: zipCode, stateCode: stateCode
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            }).then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        data: {
                            companyName: "",
                            domainName: "",
                            addressLine1: "",
                            addressLine2: "",
                            city: "",
                            zipCode: "",
                            stateCode: ""
                        },
                        showAdd: false
                    }, () => {
                        this.getCompanyList();
                        toast.success('Company added sucessfully.', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                        });
                    })
                }
            })
        } else {
            axios({
                url: '/api/company/update',
                data: this.state.data,
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            }).then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        data: {
                            companyName: "",
                            domainName: "",
                            addressLine1: "",
                            addressLine2: "",
                            city: "",
                            zipCode: "",
                            stateCode: ""
                        },
                        showEdit: false
                    }, () => {
                        this.getCompanyList();
                        toast.success('Company updated sucessfully.', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                        });
                    })
                }
            });
        }
    }

    componentDidMount() {
        this.getCompanyList();
    }

    render() {
        const props = {
            ...this.state,
            showModal: this.showModal.bind(this),
            handleChange: this.handleChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this),
            handlePageChange: this.handlePageChange.bind(this)
        }
        return (
            <div>
                <ToastContainer />
                <Add {...props} />
                <Edit {...props} />
                <ConfirmationModal />
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h4 className="m-0">Company List</h4>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button
                                    id="addBtn"
                                    className="button-solid"
                                    onClick={() => this.showModal(true, 'Add')}
                                >
                                    Add Company
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                                <thead>
                                    <tr>
                                        <th className="headerCell1">ID</th>
                                        <th className="headerCell1">Company Name</th>
                                        <th className="headerCell1">Outlets</th>
                                        <th className="headerCell1">Domain Name</th>
                                        <th className="headerCell1">Address Line 1</th>
                                        <th className="headerCell1">Address Line 2</th>
                                        <th className="headerCell1">City</th>
                                        <th className="headerCell1">Zip Code</th>
                                        <th className="headerCell1">State Code</th>
                                        <th className="headerCell1">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.companyList && this.state.companyList.map((item, key) => {
                                            return item.isActive ?
                                                (
                                                    <tr key={key}>
                                                        <th className="headerCell1" scope="row">{(this.state.currentPage * this.state.perPage) + (key + 1)}</th>
                                                        <td className="dataCell1">{item.companyName}</td>
                                                        <td className="dataCell1">
                                                            <Link
                                                                to={{
                                                                    pathname: '/companies/outlet',
                                                                    state: { companyCode: item.companyCode }
                                                                }}
                                                                className="text-decoration-none"
                                                            >
                                                                View outlet
                                                            </Link>
                                                        </td>
                                                        <td className="dataCell1"><a href={item.domainName}>{item.domainName}</a></td>
                                                        <td className="dataCell1">{item.addressLine1}</td>
                                                        <td className="dataCell1">{item.addressLine2}</td>
                                                        <td className="dataCell1">{item.city}</td>
                                                        <td className="dataCell1">{item.zipCode}</td>
                                                        <td className="dataCell1">{item.stateCode}</td>
                                                        <td className="dataCell1">
                                                            <button
                                                                id="editBtn"
                                                                className="border-0"
                                                                style={{ background: 'none' }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        data: item
                                                                    }, () => {
                                                                        this.showModal(true, 'Edit')
                                                                    })
                                                                }}
                                                                data-tooltip-id="editBtn"
                                                                data-tooltip-content="Edit"
                                                                data-tooltip-place="top"
                                                            >
                                                                <img src="/dist/images/edit.svg" alt="editIcon" width={20} />
                                                            </button>
                                                            <Tooltip id='editBtn' />
                                                            <button
                                                                id='delBtn'
                                                                className="border-0"
                                                                style={{ background: 'none' }}
                                                                onClick={() => this.handleDelete(item)}
                                                                data-tooltip-id="delBtn"
                                                                data-tooltip-content="Delete"
                                                                data-tooltip-place="top"
                                                            >
                                                                <img src="/dist/images/delete.svg" alt="delIcon" width={20} />
                                                            </button>
                                                            <Tooltip id="delBtn" />
                                                        </td>
                                                    </tr>
                                                ) : ''
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end mt-2">
                                <PaginationControl
                                    limit={this.state.perPage}
                                    total={this.state.totalCount}
                                    page={this.state.currentPage + 1}
                                    changePage={(page) => this.handlePageChange(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
};