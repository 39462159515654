import Add from "./Add";
import React from "react";
import axios from "axios";
import Edit from "./Edit";
import Videos from "./Videos";
import Manual from "./Manuals";
import Services from "./Services";
import Brochure from "./Brochures";
import { Link } from "react-router-dom";
import AssetDetails from "./AssetDetails";
import AssetServices from "./AssetServices";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../../utils/ConfirmationModal";
import { ConfirmContext } from "../../utils/ConfirmationProvider";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Tooltip } from "react-tooltip";

export default class List extends React.Component {
    static contextType = ConfirmContext;
    state = {
        data: {
            modelName: "",
            assetName: "",
            modelCode: "",
            description: "",
            brandMasterCode: "",
            assetMasterCode: ""
        },

        // Handling modals
        showAdd: false,
        showEdit: false,
        showVideo: false,
        showBrochure: false,
        showManual: false,
        showServices: false,
        showAddServices: false,
        showAssetDetail: false,

        assetList: [],
        videos: [],
        brochures: [],
        manuals: [],
        services: [],

        detailsData: {
            assetMasterCode: '',
            content: '',
            link: '',
            assetDetailType: ''
        },

        servicesData: {
            name: "",
            assetMasterCode: '',
            assetServiceType: "",
            assetServiceFrequent: ""
        },

        // Handling Pagination 
        totalCount: 0,
        currentPage: 0,
        perPage: 10,
    }

    showModal = (val, name) => {
        this.setState({
            ...this.state,
            [`show${name}`]: val,
            data: val ? this.state.data : {
                ...this.state.data,
                modelName: "",
                assetName: "",
                modelCode: "",
                description: ""
            },
            detailsData: val ? this.state.detailsData : {
                ...this.state.detailsData,
                content: '',
                link: '',
                assetDetailType: ''
            },
            servicesData: val ? this.state.servicesData : {
                ...this.state.servicesData,
                name: "",
                assetServiceType: "",
                assetServiceFrequent: ""
            },
        })
    }

    handlePageChange = (page) => {
        this.setState({
            ...this.state,
            currentPage: page - 1
        }, () => {
            this.getAssetList();
        })
    }

    handleChange(e, obj) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [obj]: {
                ...this.state[obj],
                [name]: value
            }
        })
    }

    async handleDelete(item) {
        const { getConfirmation } = this.context;
        const confirmed = await getConfirmation({
            message: 'Do you really want to delete?'
        });
        if (confirmed) {
            axios({
                url: '/api/asset-master/update/assetmaster',
                data: { ...item, isActive: false },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: this.state.data,
                            showAdd: false,
                            currentPage: this.state.assetList.length === 1 ? this.state.currentPage - 1 : this.state.currentPage
                        }, () => {
                            this.getAssetList();
                            toast.success('Asset deleted sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        }
    }

    handleSubmit = (e, type) => {
        e.preventDefault();
        const { assetName, brandMasterCode,
            description, modelCode, modelName
        } = this.state.data
        if (type === 'add') {
            axios({
                url: '/api/asset-master/add/assetmaster',
                data: {
                    assetName: assetName,
                    brandMasterCode: brandMasterCode,
                    description: description,
                    modelCode: modelCode,
                    modelName: modelName
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                modelName: "",
                                assetName: "",
                                modelCode: ""
                            },
                            showAdd: false
                        }, () => {
                            this.getAssetList();
                            toast.success('Asset added sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        } else if (type === 'edit') {
            axios({
                url: '/api/asset-master/update/assetmaster',
                data: this.state.data,
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                modelName: "",
                                assetName: "",
                                modelCode: "",
                                description: ""
                            },
                            showEdit: false
                        }, () => {
                            this.getAssetList();
                            toast.success('Asset updated sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        } else if (type === "assetDetails") {
            axios({
                url: '/api/asset-master/add/assetdetails',
                data: this.state.detailsData,
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            detailsData: {
                                ...this.state.detailsData,
                                assetMasterCode: '',
                                content: '',
                                link: '',
                                assetDetailType: ''
                            },
                            showAssetDetail: false
                        }, () => {
                            this.getAssetList();
                            toast.success('Asset detail updated sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        } else {
            axios({
                url: '/api/asset-master/add/assetservicedetails',
                data: this.state.servicesData,
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            servicesData: {
                                ...this.state.servicesData,
                                name: "",
                                assetMasterCode: '',
                                assetServiceType: "",
                                assetServiceFrequent: ""
                            },
                            showAddServices: false
                        }, () => {
                            this.getAssetList();
                            toast.success('Asset service updated sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        }
    }

    getAssetList() {
        const data = {
            page: this.state.currentPage,
            size: this.state.perPage,
            brandCode: this.state.data.brandMasterCode,
            typeOfRequest: "ALL"
        }
        axios({
            url: '/api/asset-master/list/assetmaster',
            data: data,
            method: 'post',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        assetList: data.data.assetMasterList,
                        totalCount: data.data.totalCount,
                        otpGenerated: true
                    })
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            })
    }

    componentDidMount() {
        const { brandMasterCode } = this.props.location.state;
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                brandMasterCode: brandMasterCode
            }
        }, () => {
            this.getAssetList();
        })
    }

    render() {
        const props = {
            ...this.state,
            showModal: this.showModal.bind(this),
            handleChange: this.handleChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this),
            handleDelete: this.handleDelete.bind(this)
        }
        return (
            <div>
                <Add {...props} />
                <ToastContainer />
                <Edit {...props} />
                <ConfirmationModal />
                <Services {...props} />
                <Videos {...props} />
                <Manual {...props} />
                <Brochure {...props} />
                <AssetDetails {...props} />
                <AssetServices {...props} />
                <Link to='/brands' className='m-1 text-decoration-none'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                </Link>
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h4 className="m-0">Assets List</h4>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button
                                    id="addBtn"
                                    className="button-solid"
                                    onClick={() => this.showModal(true, 'Add')}
                                >
                                    Add Asset
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="w-100" style={{ tableLayout: 'auto', borderSpacing: 2, borderCollapse: "separate" }}>
                                <thead>
                                    <tr>
                                        <th className="headerCell1">ID</th>
                                        <th className="headerCell1">Asset Name</th>
                                        <th className="headerCell1">Modal Name</th>
                                        <th className="headerCell1">Model Code</th>
                                        <th className="headerCell1">Description</th>
                                        <th className="headerCell1">Videos</th>
                                        <th className="headerCell1">Brochures</th>
                                        <th className="headerCell1">Manuals</th>
                                        <th className="headerCell1">Services</th>
                                        <th className="headerCell1">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.assetList && this.state.assetList.map((item, key) => {
                                            return item.isActive ?
                                                (
                                                    <tr key={key}>
                                                        <td className="headerCell1">{(this.state.currentPage * this.state.perPage) + (key + 1)}</td>
                                                        <td className="dataCell1">{item.assetName}</td>
                                                        <td className="dataCell1">{item.modelName}</td>
                                                        <td className="dataCell1">{item.modelCode}</td>
                                                        <td className="dataCell1">{item.description ? item.description : '-'}</td>
                                                        <td className="dataCell1">
                                                            <span
                                                                className="text-primary"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        videos: item.videos
                                                                    }, () => {
                                                                        this.showModal(true, 'Video')
                                                                    })
                                                                }}
                                                            >
                                                                View videos
                                                            </span>
                                                        </td>
                                                        <td className="dataCell1">
                                                            <span
                                                                className="text-primary"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        brochures: item.brochures
                                                                    }, () => {
                                                                        this.showModal(true, 'Brochure')
                                                                    })
                                                                }}
                                                            >
                                                                View brochure
                                                            </span>
                                                        </td>
                                                        <td className="dataCell1">
                                                            <span
                                                                className="text-primary"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        manuals: item.manuals
                                                                    }, () => {
                                                                        this.showModal(true, 'Manual')
                                                                    })
                                                                }}
                                                            >
                                                                View manuals
                                                            </span>
                                                        </td>
                                                        <td className="dataCell1">
                                                            <span
                                                                className="text-primary"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        services: item.assetServiceDTOs
                                                                    }, () => {
                                                                        this.showModal(true, 'Services')
                                                                    })
                                                                }}
                                                            >
                                                                View services
                                                            </span>
                                                        </td>
                                                        <td className="dataCell1" style={{ lineBreak: 'normal' }}>
                                                            <div className=" d-flex flex-column justify-content-center">
                                                                <div className="d-flex justify-content-center">
                                                                    <button
                                                                        id="detailBtn"
                                                                        className="button-outlined mx-1 py-2"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                ...this.state,
                                                                                detailsData: {
                                                                                    ...this.state.detailsData,
                                                                                    assetMasterCode: item.assetMasterCode
                                                                                }
                                                                            }, () => {
                                                                                this.showModal(true, 'AssetDetail')
                                                                            })
                                                                        }}
                                                                    >
                                                                        Add<br />Detail
                                                                    </button>
                                                                    <button
                                                                        id="serviceBtn"
                                                                        className="button-outlined px-2 py-2"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                ...this.state,
                                                                                servicesData: {
                                                                                    ...this.state.servicesData,
                                                                                    assetMasterCode: item.assetMasterCode
                                                                                }
                                                                            }, () => {
                                                                                this.showModal(true, 'AddServices')
                                                                            })
                                                                        }}
                                                                    >
                                                                        Add<br />Service
                                                                    </button>
                                                                </div>
                                                                <div className="d-flex justify-content-center mt-1">
                                                                    <button
                                                                        id="editBtn"
                                                                        className="border-0"
                                                                        style={{ background: 'none' }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                ...this.state,
                                                                                data: item
                                                                            }, () => {
                                                                                this.showModal(true, 'Edit')
                                                                            })
                                                                        }}
                                                                        data-tooltip-id="editBtn"
                                                                        data-tooltip-content="Edit"
                                                                        data-tooltip-place="top"
                                                                    >
                                                                        <img src="/dist/images/edit.svg" alt="editIcon" width={20} />
                                                                    </button>
                                                                    <Tooltip id='editBtn' />
                                                                    <span className="mx-1" />
                                                                    <button
                                                                        id='delBtn'
                                                                        className="border-0"
                                                                        style={{ background: 'none' }}
                                                                        onClick={() => this.handleDelete(item)}
                                                                        data-tooltip-id="delBtn"
                                                                        data-tooltip-content="Delete"
                                                                        data-tooltip-place="top"
                                                                    >
                                                                        <img src="/dist/images/delete.svg" alt="delIcon" width={20} />
                                                                    </button>
                                                                    <Tooltip id="delBtn" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : ''
                                        })
                                    }

                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end mt-2">
                                <PaginationControl
                                    between={1}
                                    limit={this.state.perPage}
                                    total={this.state.totalCount}
                                    page={this.state.currentPage + 1}
                                    changePage={(page) => this.handlePageChange(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
};