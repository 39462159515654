import React, { Component } from 'react';

export const ConfirmContext = React.createContext({});

export default class ConfirmationProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            dialogConfig: {},
        }
    }

    getConfirmation = ({ ...options }) => {
        return new Promise((res) => {
            this.openDialog({
                actionCallback: res,
                ...options
            });
        });
    }

    openDialog = ({ message, actionCallback }) => {
        this.setState({
            dialogOpen: true,
            dialogConfig: {
                message,
                actionCallback
            }
        })
    };

    resetDialog = () => {
        this.setState({
            dialogOpen: false,
            dialogConfig: {}
        })
    };

    onConfirm = () => {
        this.resetDialog();
        this.state.dialogConfig.actionCallback(true);
    };

    onDismiss = () => {
        this.resetDialog();
        this.state.dialogConfig.actionCallback(false);
    };

    render() {
        const { openDialog, onConfirm, onDismiss, getConfirmation } = this;
        return (
            <ConfirmContext.Provider value={{ ...this.state, openDialog, getConfirmation, onConfirm, onDismiss }}>
                {this.props.children}
            </ConfirmContext.Provider >
        )
    }
}