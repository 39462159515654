import React from "react";
import axios from "axios";
import { country } from "../../utils/countryCode";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";

class Login extends React.Component {
    state = {
        countryCode: '',
        contact: '',
        otp: '',
        otpGenerated: false,
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value
        })
    }

    handleGenerateOTP = (e, resend) => {
        e.preventDefault();
        const data = {
            countryCode: this.state.countryCode,
            phone: this.state.contact,
        }
        if (resend) {
            toast.success('OTP sent. Check your phone.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
        }
        axios({
            url: '/api/user/generate-otp',
            data: data,
            method: 'post',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
            
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        otpGenerated: true
                    })
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
    }

    handleVerifyOTP = (e) => {
        e.preventDefault();
        const data = {
            countryCode: this.state.countryCode,
            phone: this.state.contact,
            otp: this.state.otp,
            userAccountType: "VENDOR"
        }
        axios({
            url: '/api/user/login',
            data: data,
            method: 'post',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.props.history.push('/brands')
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
    }

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <ToastContainer />
                <div className="row d-flex justify-content-center m-5 p-5">
                    <div className="col-sm-5">
                        <img src='/dist/images/login.png' alt="Login" className="w-100" />
                    </div>
                    <div className="col-sm-5">
                        <div className="d-flex flex-column p-5">
                            <div className="mb-5" >
                                <h3 className="color1">Sign In</h3>
                                <div className="color2" style={{ fontSize: 16 }}>Welcome back! Please signin to continue.</div>
                            </div>
                            <div>
                                {
                                    this.state.otpGenerated ?
                                        (
                                            <form onSubmit={this.handleVerifyOTP}>
                                                <div className="form-group required mb-4">
                                                    <label className="control-label" mb="5px" htmlFor="otp">
                                                        OTP
                                                    </label>
                                                    <input
                                                        id="otp"
                                                        name="otp"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.otp}
                                                        placeholder="Enter OTP"
                                                        onChange={e => this.handleChange(e)}
                                                    />
                                                </div>
                                                <div className="my-2 d-flex justify-content-center">
                                                    <button type="submit" className="button-outlined w-75">
                                                        Verify OTP
                                                    </button>
                                                </div>
                                                <div className="my-2 text-center" style={{ fontSize: 13 }}>
                                                    Did't receive OTP?<span className="mx-1" />
                                                    <Link to='' onClick={(e) => this.handleGenerateOTP(e, true)} className="text-decoration-none">Resend OTP</Link>
                                                </div>
                                            </form>
                                        ) :
                                        (
                                            <form onSubmit={(e) => this.handleGenerateOTP(e, false)}>
                                                <div className="row mx-0">
                                                    <div className="col-sm-6 px-1">
                                                        <div className="form-group required mb-4">
                                                            <label className="control-label" mb="5px" htmlFor="countryCode">
                                                                Code
                                                            </label>
                                                            <select
                                                                id="countryCode"
                                                                name="countryCode"
                                                                type="countryCode"
                                                                className="form-select"
                                                                onChange={e => this.handleChange(e)}
                                                            >
                                                                <option value=''>Select country code</option>
                                                                {
                                                                    country.map((item, key) => {
                                                                        return <option key={key} value={item.code}>{item.name} ({item.code})</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 px-1">
                                                        <div className="form-group required mb-4">
                                                            <div className="d-flex">
                                                                <label className="control-label" mb="5px" htmlFor="contact">
                                                                    Contact No.
                                                                </label>
                                                            </div>
                                                            <input
                                                                id="contact"
                                                                name="contact"
                                                                type="contact"
                                                                className="form-control"
                                                                value={this.state.contact}
                                                                placeholder="Enter contact no."
                                                                onChange={e => this.handleChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-2 d-flex justify-content-center">
                                                    <button type="submit" className="button-outlined w-75">
                                                        Send OTP
                                                    </button>
                                                </div>
                                                {/* <div className="d-flex justify-content-center" style={{ fontSize: 13 }}>
                                                    Don&apos;t have an account?<span className="mx-1" />
                                                    <a href="/signup" className="text-decoration-none" >Create an Account</a>
                                                </div> */}
                                            </form>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
};

export default Login