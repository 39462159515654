import React from "react";
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
const $ = window.$;

class Sidebar extends React.Component {
    handleSidebarToggle = () => {
        if ($) {
            let elem = $(".page-wrapper");
            $(".sidebar-toggler").on("click", function () {
                if (elem.hasClass("sidebar-open")) {
                    elem.removeClass("sidebar-open").addClass("sidebar-closed");
                    $(".sidebar").trigger("closed.sidebar");
                }
                else {
                    elem.removeClass("sidebar-closed").addClass("sidebar-open");
                    $(".sidebar").trigger("opened.sidebar");
                }
            });
        }
    }

    handleLogout() {
        axios({
            url: '/api/user/logout',
            data: {},
            method: 'post',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        }).then().catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        })
    }

    componentDidMount() {
        if (window.outerWidth < 1024) {
            if ($) {
                let elem = $(".page-wrapper");
                elem.removeClass("sidebar-open").addClass("sidebar-closed");
                $(".sidebar").trigger("closed.sidebar");
            }
        }
        this.handleSidebarToggle();
    }

    render() {
        const activeClass = 'active';
        const currentPath = window.location.pathname;
        const vendorCode = localStorage.getItem('vendorCode');
        return (
            <nav id="sidebar" className="sidebar ps ps--active-y">
                <div className="sidebar-brand bg-white">
                    {/* LOGO */}
                    {/* <img src="LOGO" alt="logo" className='img m-auto' /> */}
                    <img src="/dist/images/logo.png" alt="logo" className="img-sm m-auto" />
                </div>
                <ul className="sidebar-menu">
                    {/* <div>
                        <h5 style={{ fontWeight: 500 }} className="fw-normal my-0 text-uppercase">Dashboard</h5>
                    </div> */}
                    {
                        (!currentPath.includes("vnd") && !currentPath.includes("teams")) ?
                            (
                                <>
                                    <li className={(currentPath.includes("brands")) ? activeClass : ''}>
                                        <Link to="/brands">
                                            <img src='/dist/images/brand.png' height={28} style={{ marginRight: 20 }} alt="brands" />
                                            <span style={{ fontSize: 15 }}>Brands</span>
                                        </Link>
                                    </li>
                                    <li className={(currentPath.includes("vendors")) ? activeClass : ''}>
                                        <Link to="/vendors">
                                            <img src='/dist/images/vendor.png' height={28} style={{ marginRight: 20 }} alt="brands" />
                                            <span style={{ fontSize: 15 }}>Vendors</span>
                                        </Link>
                                    </li>
                                    <li className={(currentPath.includes("companies")) ? activeClass : ''}>
                                        <Link to="/companies">
                                            <img src='/dist/images/company.png' height={28} style={{ marginRight: 20 }} alt="brands" />
                                            <span style={{ fontSize: 15 }}>Companies & Outlets</span>
                                        </Link>
                                    </li>
                                </>
                            ) : ''
                    }
                    {
                        currentPath.includes("vnd") || currentPath.includes("teams") ?
                            (
                                <>
                                    <li className={(currentPath.includes("vnd")) ? activeClass : ''}>
                                        <Link to={`/vnd/tkt/vendorCode=${vendorCode}`}>
                                            <img src='/dist/images/ticket.png' height={28} style={{ marginRight: 20 }} alt="Tickets" />
                                            <span style={{ fontSize: 15 }}>Tickets</span>
                                        </Link>
                                    </li>
                                    <li className={(currentPath.includes("teams")) ? activeClass : ''}>
                                        <Link to="/teams">
                                            <img src='/dist/images/teams.png' height={28} style={{ marginRight: 20 }} alt="Teams" />
                                            <span style={{ fontSize: 15 }}>Teams</span>
                                        </Link>
                                    </li>
                                </>
                            ) : ''
                    }
                    <div className="profile">
                        <div className="d-flex my-2">
                            <div className="avatarParent">
                                <span className="avatar">T</span>
                            </div>
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <button
                                    style={{ border: 'none', outline: 'none', background: 'transparent' }}
                                    onClick={() => this.handleLogout()}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="mb-4">
                            <button className="avatarBtn">
                                <p className="my-0 fw-bold">K Abhinav Rao</p>
                            </button>
                            <p style={{ fontSize: 13 }} className="color2">Administrator</p>
                        </div>

                    </div>
                </ul>
            </nav >
        )
    }
}

export default withRouter(Sidebar);