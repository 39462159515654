import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import { ToastContainer, toast } from "react-toastify";

export default class TicketList extends React.Component {
    state = {
        editItem: {},
        ticketList: [],

        // Handling Pagination 
        totalCount: 0,
        currentPage: 0,
        perPage: 10
    }

    getTickets() {
        const { currentPage, perPage } = this.state;
        // const vendorCode = window.location.pathname.split('=')[1];
        const vendorCode = localStorage.getItem('vendorCode');
        axios({
            url: `/api/vendor/opn/ticket/request?vendorCode=${vendorCode}&page=${currentPage}&size=${perPage}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        ticketList: data.data,
                    })
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
    }

    componentDidMount() {
        this.getTickets();
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <div className="col-sm-6">
                                <h4 className="m-0">Tickets</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                                <thead>
                                    <tr>
                                        <th className="headerCell1">ID</th>
                                        <th className="headerCell1">Vendor Name</th>
                                        <th className="headerCell1">Ticket Name</th>
                                        <th className="headerCell1">Request Date</th>
                                        <th className="headerCell1" style={{ width: '60px' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.ticketList && this.state.ticketList.map((item, key) => {
                                            return <tr key={key}>
                                                <th className="headerCell1" scope="row">{(this.state.currentPage * this.state.perPage) + (key + 1)}</th>
                                                <td className="dataCell1">{item.vendorName}</td>
                                                <td className="dataCell1">{item.ticketName}</td>
                                                <td className="dataCell1">{item.requestDate}</td>
                                                <td className="dataCell1">
                                                    <div className="d-flex justify-content-center flex-row">
                                                        <Link
                                                            to={{
                                                                pathname: `/vnd/rqt/${item.code}`,
                                                                state: {
                                                                    editItem: item
                                                                }
                                                            }}
                                                            className="text-decoration-none"
                                                            data-tooltip-id="showDetails"
                                                            data-tooltip-content="View Details"
                                                            data-tooltip-place="top"
                                                        >
                                                            <img src="/dist/images/eye.svg" alt='view' />
                                                        </Link>
                                                        <Tooltip id="showDetails" />
                                                        {/* {
                                                            item.ticketVendorAllocationStatus !== 'ALLOCATED' ?
                                                                <div>
                                                                    <span className="mx-1" />
                                                                    <button
                                                                        id="editBtn"
                                                                        className="button-outlined"
                                                                        onClick={() => this.handleApproval(item.code)}
                                                                    >
                                                                        Approve
                                                                    </button> */}
                                                        {/* <span className="mx-1" /> */}
                                                        {/* <button
                                                                        id='delBtn'
                                                                        className="button-outlined"
                                                                    // onClick={() => this.handleDelete(item)}
                                                                    >
                                                                        Reject
                                                                    </button> */}
                                                        {/* </div>
                                                                : ''
                                                        } */}
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}