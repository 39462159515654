import React from "react";
import Modal, { ModalBody, ModalHeader } from "../Modal";

export default class Services extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showServices}>
                <ModalHeader>
                    <h4 className="m-0">Services</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showModal(false, 'Services')}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                        <thead>
                            <tr>
                                <th className="headerCell1">ID</th>
                                <th className="headerCell1" scope="col">Name</th>
                                <th className="headerCell1" scope="col">Service Type</th>
                                <th className="headerCell1" scope="col">Service Frequent</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.services && this.props.services.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <th className="headerCell1" scope="row">{key + 1}</th>
                                            <td className="dataCell1">{item.name}</td>
                                            <td className="dataCell1">{item.assetServiceType}</td>
                                            <td className="dataCell1">{item.assetServiceFrequent}</td>
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </table>
                </ModalBody>
            </Modal >
        )
    }
}