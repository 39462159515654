import React from 'react';
import { ConfirmContext } from './ConfirmationProvider';

class ConfirmationModal extends React.Component {
    static contextType = ConfirmContext;
    render() {
        const { dialogOpen, dialogConfig, onDismiss, onConfirm } = this.context;
        const show = dialogOpen ? 'show' : '';
        const display = dialogOpen ? 'block' : 'none';
        return (
            <div
                className={'modal fade ' + show}
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
                style={{ display: display, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
                <div className="modal-dialog modal-dialog-centered modal-confirm confirm-danger">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h4 className="modal-title">Are you sure?</h4>
                        </div>
                        <div className="modal-body">
                            <p className="text-center">{dialogConfig.message}</p>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <div className="px-2">
                                <button id="yes" className="btn my-1 btn-primary btn-rounded" onClick={onConfirm}>Yes</button>
                            </div>
                            <div className="px-2">
                                <button className="btn my-1 btn-danger btn-rounded" onClick={onDismiss}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default ConfirmationModal;