import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default class TicketDetails extends React.Component {
    state = {
        editItem: {},
        ticketDetails: {}
    }

    handleApproval(code) {
        axios({
            url: `/api/ticket/request-approval?code=${code}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    toast.success(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    getTicketDetails() {
        const { editItem } = this.state;
        axios({
            url: `/api/ticket/get/by-code?ticketCode=${editItem.ticketCode}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        }).then((response) => {
            let data = response.data;
            if (data.responseCode === '1000') {
                this.setState({
                    ...this.state,
                    ticketDetails: data.data,
                })
            } else {
                toast.error(data.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        });
    }

    componentDidMount() {
        const { editItem } = this.props.location.state;
        this.setState({
            editItem
        }, () => {
            this.getTicketDetails();
        });
    }

    render() {
        const { editItem, ticketDetails } = this.state;
        return (
            <div>
                <ToastContainer />
                <Link to={{
                    pathname: '/vendors/tickets',
                    state: {
                        editItem: editItem
                    }
                }} className='m-1 text-decoration-none'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                </Link>
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h4 className="m-0">Ticket Details</h4>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <span className="mx-1" />
                                {
                                    ticketDetails.ticket && ticketDetails.ticket.ticketStatus !== "VENDOR_ALLOCATED" ?
                                        <div>
                                            <button
                                                id="approveBtn"
                                                className="button-solid"
                                                onClick={() => this.handleApproval(editItem.code, true)}
                                            >
                                                Approve
                                            </button>
                                            <span className="mx-1" />
                                            <button
                                                id='rejectBtn'
                                                className="button-solid"
                                            // onClick={() => this.handleReject(item)}
                                            >
                                                Reject
                                            </button>
                                            <span className="mx-1" />
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                Object.keys(ticketDetails).length ?
                                    <div>
                                        <div>
                                            Ticket #<span className="text-secondary">{editItem.ticketCode}</span><br />
                                            <p style={{ fontSize: 12, margin: 0 }}>Created on {ticketDetails.ticket.creationDate}</p>
                                            {
                                                ticketDetails.ticket && ticketDetails.ticket.ticketStatus !== "VENDOR_ALLOCATED" ?
                                                    '' :
                                                    <p style={{ fontSize: 13 }}>The ticket has been approved.</p>
                                            }
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="w-50">
                                                <p className="text-uppercase my-1">description</p>
                                                <p className="font1">{ticketDetails.ticket.description}</p>
                                            </div>
                                            <div className="w-50">
                                                <p className="my-2 text-uppercase">multimedia description</p>
                                                {
                                                    ticketDetails.ticket && ticketDetails.ticket.multimediaDescription ?
                                                        <p className="font1">{ticketDetails.ticket.multimediaDescription}</p>
                                                        : <p className="font1">N/A</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <p className="my-2">TICKET INFORMATION</p>
                                            {
                                                ticketDetails.ticket ?
                                                    <ul/*  className="w-50"  */ style={{ paddingInlineStart: 0, fontSize: 14 }}>
                                                        <li className="d-flex justify-content-sm-between">
                                                            <span>Ticket Name</span>
                                                            <span>{ticketDetails.ticket.ticketName}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-sm-between">
                                                            <span>Vendor Name</span>
                                                            <span>{editItem.vendorName}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-sm-between">
                                                            <span>Asset Name</span>
                                                            <span>{ticketDetails.assetName}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-sm-between">
                                                            <span>Outlet Name</span>
                                                            <span>{ticketDetails.outletName}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-sm-between">
                                                            <span>Outlet Address</span>
                                                            <span>{ticketDetails.outletAddress}</span>
                                                        </li>
                                                    </ul>
                                                    : 'N/A'
                                            }
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}