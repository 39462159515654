import Add from "./Add";
import React from "react";
import axios from "axios";
import Edit from "./Edit";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../../utils/ConfirmationModal";
import { ConfirmContext } from "../../utils/ConfirmationProvider";
import { Tooltip } from "react-tooltip";

export default class List extends React.Component {
    static contextType = ConfirmContext;
    state = {
        data: {
            name: '',
            phone: '',
            countryCode: ''
        },
        teamList: [],

        // Handling modals
        showAdd: false,
        showEdit: false,
    }

    async handleActive(item) {
        const { getConfirmation } = this.context;
        const confirmed = await getConfirmation({
            message: `Do you really want to ${!item.activate ? 'de-activate' : 'activate'}?`
        });
        if (confirmed) {
            axios({
                url: '/api/vendor/opn/team/update',
                data: {
                    ...item,
                    isActive: false
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: this.state.data,
                        }, () => {
                            this.getTeams();
                            toast.success(`Team ${item.activate ? 'de-activated' : 'activated'} sucessfully.`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                });
        }
    }

    handleSubmit = (e, type) => {
        e.preventDefault();
        if (type === 'add') {
            const { name, phone, countryCode } = this.state.data;
            const vendorCode = localStorage.getItem('vendorCode');
            axios({
                url: '/api/vendor/opn/team/add',
                data: {
                    name: name,
                    phone: phone,
                    countryCode: countryCode,
                    vendorCode: vendorCode,
                    isActive: true
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                name: '',
                                phone: '',
                                countryCode: ''
                            },
                            showAdd: false
                        }, () => {
                            this.getTeams();
                            toast.success('Team added sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        } else {
            axios({
                url: '/api/vendor/opn/team/update',
                data: this.state.data,
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                name: '',
                                phone: '',
                                countryCode: ''
                            },
                            showEdit: false
                        }, () => {
                            this.getTeams();
                            toast.success('Team updated sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                });
        }
    }

    showModal = (val, name) => {
        this.setState({
            ...this.state,
            [`show${name}`]: val,
            data: val ? this.state.data : {
                ...this.state.data,
                name: "",
                phone: '',
                countryCode: ''
            }
        })
    }

    handleChange(e, obj) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [obj]: {
                ...this.state[obj],
                [name]: value
            }
        })
    }

    getTeams() {
        const vendorCode = localStorage.getItem('vendorCode');
        axios({
            url: `/api/vendor/opn/team/list?vendorCode=${vendorCode}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        teamList: data.data,
                    })
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
    }

    componentDidMount() {
        this.getTeams();
    }

    render() {
        const props = {
            ...this.state,
            showModal: this.showModal.bind(this),
            handleChange: this.handleChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this)
        }
        return (
            <div>
                <ToastContainer />
                <Add {...props} />
                <Edit {...props} />
                <ConfirmationModal />
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h4 className="m-0">Teams</h4>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button
                                    id="addBtn"
                                    className="button-solid"
                                    onClick={() => this.showModal(true, 'Add')}
                                >
                                    Add Team
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                                <thead>
                                    <tr>
                                        <th className="headerCell1">ID</th>
                                        <th className="headerCell1">Name</th>
                                        <th className="headerCell1">Phone</th>
                                        <th className="headerCell1">Country Code</th>
                                        <th className="headerCell1" style={{ width: '60px' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.teamList && this.state.teamList.map((item, key) => {
                                            return <tr key={key}>
                                                <th className="headerCell1" scope="row">{key + 1}</th>
                                                <td className="dataCell1">{item.name}</td>
                                                <td className="dataCell1">{item.phone}</td>
                                                <td className="dataCell1">{item.countryCode}</td>
                                                <td className="dataCell1">
                                                    <div className="d-flex justify-content-center flex-row">
                                                        <button
                                                            id="editBtn"
                                                            className="border-0"
                                                            style={{ background: 'none' }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    data: item
                                                                }, () => {
                                                                    this.showModal(true, 'Edit')
                                                                })
                                                            }}
                                                            data-tooltip-id="editBtn"
                                                            data-tooltip-content="Edit"
                                                            data-tooltip-place="top"
                                                        >
                                                            <img src="/dist/images/edit.svg" alt="editIcon" width={20} />
                                                        </button>
                                                        <Tooltip id='editBtn' />
                                                        <button
                                                            id='deactivate'
                                                            className="border-0"
                                                            style={{ background: 'none' }}
                                                            onClick={() => this.handleActive(item)}
                                                            data-tooltip-id="deactivate"
                                                            data-tooltip-content="De-activate"
                                                            data-tooltip-place="top"
                                                        >
                                                            <img src="/dist/images/delete.svg" alt="delIcon" width={20} />
                                                        </button>
                                                        <Tooltip id="deactivate" />
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}