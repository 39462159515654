import Add from "./Add";
import React from "react";
import axios from "axios";
// import Edit from "./Edit";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import ConfirmationModal from "../../../utils/ConfirmationModal";
import { ConfirmContext } from "../../../utils/ConfirmationProvider";
import { PaginationControl } from 'react-bootstrap-pagination-control';

export default class List extends React.Component {
    static contextType = ConfirmContext;
    state = {
        data: {
            firstName: "Shubhanshu",
            lastName: "Srivastava",
            countryCode: "91",
            phone: "9999996899",
            email: "",
            outletCode: "VVVVV",
            vendorCode: null,
            userAccountType: "OUTLET",
            userRole: "OUTLET_ADMIN",
            verificationRequired: true
        },
        companyCode: '',
        outladetList: [],
        // Handling modals
        showAdd: false,
        showEdit: false,
        // Handling Pagination 
        totalCount: 0,
        currentPage: 0,
        perPage: 10,
    }

    handlePageChange = (page) => {
        this.setState({
            ...this.state,
            currentPage: page - 1
        }, () => {
            this.getAssetList();
        })
    }

    handleChange(e, obj) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [obj]: {
                ...this.state[obj],
                [name]: value
            }
        })
    }

    async handleDelete(item) {
        const { getConfirmation } = this.context;
        const confirmed = await getConfirmation({
            message: 'Do you really want to delete?'
        });
        if (confirmed) {
            axios({
                url: '/api/company/outlet/update',
                data: {
                    ...item,
                    outletCode: item.companyOutletCode,
                    isActive: false
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: this.state.data,
                            currentPage: this.state.outletList.length === 1 ? this.state.currentPage - 1 : this.state.currentPage
                        }, () => {
                            this.getAdminList();
                            toast.success('Outlet deleted sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                });
        }
    }

    handleSubmit = (e, type) => {
        e.preventDefault();
        if (type === 'add') {
            // const {
            //     companyOutletName, outletType, domainName,
            //     addressLine1, addressLine2, city,
            //     zipCode, stateCode, latitude, longitude, outletCode
            // } = this.state.data;
            axios({
                url: '/api/user/add',
                data: this.state.data,
                //  {
                //     companyOutletName: companyOutletName,
                //     outletType: outletType,
                //     domainName: domainName,
                //     addressLine1: addressLine1,
                //     addressLine2: addressLine2,
                //     city: city,
                //     zipCode: zipCode,
                //     stateCode: stateCode,
                //     latitude: latitude,
                //     longitude: longitude,
                //     outletCode: outletCode,
                // },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: this.state.data,
                            //  {
                            //     ...this.state.data,
                            //     companyOutletName: "",
                            //     outletType: '',
                            //     domainName: '',
                            //     addressLine1: "",
                            //     addressLine2: "",
                            //     city: "",
                            //     zipCode: "",
                            //     stateCode: "",
                            //     latitude: '',
                            //     longitude: '',
                            //     facilityMiniDTOList: []
                            // },
                            showAdd: false
                        }, () => {
                            // this.getAdminList();
                            toast.success('Admin added sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        } else {
            axios({
                url: '/api/company/outlet/update',
                data: {
                    ...this.state.data,
                    outletCode: this.state.data.companyOutletCode
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                companyOutletName: "",
                                outletType: '',
                                domainName: '',
                                addressLine1: "",
                                addressLine2: "",
                                city: "",
                                zipCode: "",
                                stateCode: "",
                                latitude: '',
                                longitude: '',
                                facilityMiniDTOList: [],
                            },
                            showEdit: false
                        }, () => {
                            this.getAdminList();
                            toast.success('Outlet updated sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                });
        }
    }

    showModal = (val, name) => {
        this.setState({
            ...this.state,
            [`show${name}`]: val,
            // data: val ? this.state.data : {
            //     ...this.state.data,
            //     companyOutletName: "",
            //     outletType: "",
            //     domainName: '',
            //     addressLine1: "",
            //     addressLine2: "",
            //     city: "",
            //     zipCode: "",
            //     stateCode: "",
            //     latitude: '',
            //     longitude: '',
            //     outletCode: '',
            //     facilityMiniDTOList: []
            // }
        })
    }

    getAdminList = () => {
        axios({
            url: `/api/company/outlet/list?isActive=true&page=${this.state.currentPage}&size=${this.state.perPage}&outletCode=${this.state.data.outletCode}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        }).then((response) => {
            let data = response.data;
            if (data.responseCode === '1000') {
                this.setState({
                    ...this.state,
                    outletList: data.data.companyOutletList.filter((item) => {
                        if (item.isActive) {
                            return item;
                        }
                        return null;
                    }),
                    totalCount: this.state.outletList.length
                })
            } else {
                toast.error(data.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        })
    }

    componentDidMount() {
        const { companyCode } = this.props.location.state;
        this.setState({
            ...this.state,
            companyCode: companyCode
        }, () => {
            // this.getAdminList();
        })
    }

    render() {
        const props = {
            ...this.state,
            showModal: this.showModal.bind(this),
            handleChange: this.handleChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this),
            handlePageChange: this.handlePageChange.bind(this)
        }
        return (
            <div>
                <Add {...props} />
                <ToastContainer />
                {/* <Edit {...props} />
                <ConfirmationModal /> */}
                <Link to={{
                    pathname: '/companies/outlet',
                    state: {
                        companyCode: this.state.companyCode,
                        outletCode: this.state.outletCode
                    }
                }} className='m-1 text-decoration-none'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                </Link>
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h4>Admin List</h4>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button
                                    id="addBtn"
                                    className="button-solid"
                                    onClick={() => this.showModal(true, 'Add')}
                                >
                                    Add Admin
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            N/A
                            {/* <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                                <thead>
                                    <tr>
                                        <th className="headerCell1" width='2%'>ID</th>
                                        <th className="headerCell1">Name</th>
                                        <th className="headerCell1">Type</th>
                                        <th className="headerCell1">Domain Name</th>
                                        <th className="headerCell1">Address Line 1</th>
                                        <th className="headerCell1">Address Line 2</th>
                                        <th className="headerCell1">City</th>
                                        <th className="headerCell1">Zip Code</th>
                                        <th className="headerCell1">State Code</th>
                                        <th className="headerCell1">Latitude</th>
                                        <th className="headerCell1">Longitude</th>
                                        <th className="headerCell1">Admins</th>
                                        <th className="headerCell1" width='10%'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.outletList && this.state.outletList.map((item, key) => {
                                            return item.isActive ?
                                                (
                                                    <tr key={key}>
                                                        <th className="headerCell1">{(this.state.currentPage * this.state.perPage) + (key + 1)}</th>
                                                        <td className="dataCell1">{item.companyOutletName}</td>
                                                        <td className="dataCell1">{item.outletType}</td>
                                                        <td className="dataCell1">{item.domainName}</td>
                                                        <td className="dataCell1">{item.addressLine1}</td>
                                                        <td className="dataCell1">{item.addressLine2}</td>
                                                        <td className="dataCell1">{item.addressLine2}</td>
                                                        <td className="dataCell1">{item.city}</td>
                                                        <td className="dataCell1">{item.stateCode}</td>
                                                        <td className="dataCell1">{item.latitude}</td>
                                                        <td className="dataCell1">{item.longitude}</td>
                                                        <td className="dataCell1">

                                                        </td>
                                                        <td className="dataCell1">
                                                            <button
                                                                id="editBtn"
                                                                className="border-0"
                                                                style={{ background: 'none' }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        data: item
                                                                    }, () => {
                                                                        this.showModal(true, 'Edit')
                                                                    })
                                                                }}
                                                            >
                                                                <img src="/dist/images/edit.png" alt="editIcon" width={18} />
                                                            </button>
                                                            <span className="mx-2" />
                                                            <button
                                                                id='delBtn'
                                                                className="border-0"
                                                                style={{ background: 'none' }}
                                                                onClick={() => this.handleDelete(item)}
                                                            >
                                                                <img src="/dist/images/delete.png" alt="delIcon" width={18} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ) : ''

                                        })
                                    }
                                </tbody>
                            </table> */}
                            <div className="d-flex justify-content-end mt-2">
                                <PaginationControl
                                    limit={this.state.perPage}
                                    total={this.state.totalCount}
                                    page={this.state.currentPage + 1}
                                    changePage={(page) => this.state.handlePageChange(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
};