import React from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../Modal";

export default class AssetServices extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showAddServices}>
                <ModalHeader>
                    <h4 className="m-0">Add Asset Services</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showModal(false, 'AddServices')}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <form style={{ width: 500 }}>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="name">
                                Service Name
                            </label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter service name"
                                value={this.props.servicesData.name}
                                onChange={e => this.props.handleChange(e, 'servicesData')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="assetServiceType">
                                    Service Type
                                </label>
                            </div>
                            <select
                                id="assetServiceType"
                                name="assetServiceType"
                                className="form-select"
                                value={this.props.servicesData.assetServiceType}
                                placeholder="Enter asset service type"
                                onChange={e => this.props.handleChange(e, 'servicesData')}
                            >
                                <option value=''>Select service type</option>
                                <option value='SELF_SERVICE'>Self Service</option>
                                <option value='VENDOR_SERVICE'>Vendor Service</option>
                                <option value='SERVICE_INTERNAL'>Service Internal</option>
                            </select>
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="assetServiceFrequent">
                                    Service Frequency
                                </label>
                            </div>
                            <select
                                id="assetServiceFrequent"
                                name="assetServiceFrequent"
                                className="form-select"
                                value={this.props.servicesData.assetServiceFrequent}
                                placeholder="Enter asset service frequency"
                                onChange={e => this.props.handleChange(e, 'servicesData')}
                            >
                                <option value=''>Select service frequency</option>
                                <option value='DAILY'>Daily</option>
                                <option value='WEEKLY'>Weekly</option>
                                <option value='FORTNIGHTLY'>Fortnightly</option>
                                <option value='MONTHLY'>Monthly</option>
                                <option value='QUARTERLY'>Quarterly</option>
                                <option value='SIXMONTHLY'>Six Monthly</option>
                                <option value='YEARLY'>Yearly</option>
                                <option value='ONCEINTWOYEAR'>Once in two year</option>
                            </select>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="button-outlined"
                        onClick={(e) => this.props.handleSubmit(e, 'assetServices')}
                    >
                        Submit
                    </button>
                </ModalFooter>
            </Modal >
        )
    }
}