import React from "react";

export default class Header extends React.Component {
    render() {
        return (
            <div className="navigation-wrapper">
                <nav className="navbar navbar-top navbar-expand-lg navbar-light bg-white pt-3 pl-3" style={{ height: '62px' }}>
                    <div className="container-fluid">
                        <button type="button " className="navbar-toggler navbar-toggler-css navbar-menu-toggler collapsed sidebar-toggler">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </button>
                    </div>
                </nav >
            </div >
        )
    }
}