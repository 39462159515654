import React from "react";
import { country } from "../../utils/countryCode";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../Modal";

export default class Edit extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showEdit}>
                <ModalHeader>
                    <h4 className="m-0">Edit Team</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showModal(false, 'Edit')}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <form style={{ width: 500 }}>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="name">
                                Name
                            </label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                value={this.props.data.name}
                                placeholder="Enter name"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="phone">
                                    Phone No.
                                </label>
                            </div>
                            <input
                                id="phone"
                                name="phone"
                                type="url"
                                className="form-control"
                                value={this.props.data.phone}
                                placeholder="Enter phone no."
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="countryCode">
                                    Country Code
                                </label>
                            </div>
                            <select
                                id="countryCode"
                                name="countryCode"
                                type="countryCode"
                                className="form-select"
                                value={this.props.data.countryCode}
                                onChange={e => this.props.handleChange(e, 'data')}
                            >
                                <option value=''>Select country code</option>
                                {
                                    country.map((item, key) => {
                                        return <option key={key} value={item.code}>{item.name} ({item.code})</option>
                                    })
                                }
                            </select>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="button-outlined"
                        onClick={(e) => this.props.handleSubmit(e, 'edit')}>
                        Submit
                    </button>
                </ModalFooter>
            </Modal >
        )
    }
}