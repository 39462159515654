import React from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../Modal";

export default class Add extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showAdd}>
                <ModalHeader>
                    <h4 className="m-0">Add Asset</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showModal(false, 'Add')}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <form style={{ width: 500 }}>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="assetName">
                                Asset Name
                            </label>
                            <input
                                id="assetName"
                                name="assetName"
                                type="text"
                                className="form-control"
                                value={this.props.data.assetName}
                                placeholder="Enter asset name"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="modelName">
                                    Model Name
                                </label>
                            </div>
                            <input
                                id="modelName"
                                name="modelName"
                                type="text"
                                className="form-control"
                                value={this.props.data.modelName}
                                placeholder="Enter model name"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="modelCode">
                                    Model Code
                                </label>
                            </div>
                            <input
                                id="modelCode"
                                name="modelCode"
                                type="text"
                                className="form-control"
                                value={this.props.data.modelCode}
                                placeholder="Enter model code"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="description">
                                    Description
                                </label>
                            </div>
                            <textarea
                                id="description"
                                name="description"
                                type="text"
                                className="form-control"
                                value={this.props.data.description}
                                placeholder="Enter description"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="button-outlined"
                        onClick={(e) => this.props.handleSubmit(e, 'add')}
                    >
                        Submit
                    </button>
                </ModalFooter>
            </Modal >
        )
    }
}