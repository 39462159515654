import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

export default class Main extends React.Component {
    render() {
        return (
            <div id="Toogle" className='page-wrapper navbar-fixed sidebar-open'>
                <Sidebar />
                <main className="main-content">
                    <Header />
                    <div className="page-container">
                        <div className="page-content">
                            {this.props.children}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}