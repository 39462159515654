import React from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../Modal";

export default class MarkComplete extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showComplete}>
                <ModalHeader>
                    <h4 className="m-0">Ticket Closure</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showModal(false, 'Complete')}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody >
                    <form style={{ width: 400, maxWidth: '100%' }}>
                        <div className="form-group required mb-2">
                            <label className="control-label" mb="5px" htmlFor="closureDescription">
                                Closure Description
                            </label>
                            <textarea
                                id="closureDescription"
                                name="closureDescription"
                                type="text"
                                className="form-control"
                                value={this.props.closureData.closureDescription}
                                placeholder="Enter closure description"
                                onChange={e => this.props.handleChange(e, 'closureData')}
                            />
                        </div>
                        <div className="form-group required">
                            <label className="control-label" mb="5px" htmlFor="closureFiles">
                                Closure Files
                            </label>
                            <input
                                id="closureFiles"
                                name="closureFiles"
                                type="file"
                                multiple
                                className="form-control"
                                accept="image"
                                placeholder="Upload closure files"
                                onChange={e => this.props.handleAddFile(e)}
                            />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="button-outlined"
                        onClick={(e) => this.props.handleSubmit(e, 'completeData')}>
                        Mark Complete
                    </button>
                </ModalFooter>
            </Modal >
        )
    }
}