import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default class TicketRequests extends React.Component {
    state = {
        editItem: {},
        ticketList: [],

        // Handling Pagination 
        totalCount: 0,
        currentPage: 0,
        perPage: 10
    }

    handleApproval(code) {
        axios({
            url: `/api/ticket/request-approval?code=${code}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        }).then((response) => {
            let data = response.data;
            if (data.responseCode === '1000') {
                toast.success(data.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
                this.getTickets();
            } else {
                toast.error(data.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        });
    }

    getTickets() {
        const { editItem, currentPage, perPage } = this.state;
        axios({
            url: `/api/vendor/ticket/request?vendorCode=${editItem.vendorCode}&page=${currentPage}&size=${perPage}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        ticketList: data.data,
                    })
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            })
    }

    componentDidMount() {
        const { editItem } = this.props.location.state;
        this.setState({
            editItem
        }, () => {
            this.getTickets();
        });
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <Link to='/vendors' className='m-1 text-decoration-none'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                </Link>
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <h4 className="m-0">Ticket Requests</h4>
                        </div>
                        <div className="card-body">
                            <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                                <thead>
                                    <tr>
                                        <th className="headerCell1">ID</th>
                                        <th className="headerCell1">Vendor Name</th>
                                        <th className="headerCell1">Ticket Name</th>
                                        <th className="headerCell1">Request Date</th>
                                        <th className="headerCell1" style={{ width: '300px' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.ticketList && this.state.ticketList.map((item, key) => {
                                            return <tr key={key}>
                                                <th className="headerCell1" scope="row">{(this.state.currentPage * this.state.perPage) + (key + 1)}</th>
                                                <td className="dataCell1">{item.vendorName}</td>
                                                <td className="dataCell1">{item.ticketName}</td>
                                                <td className="dataCell1">{item.requestDate}</td>
                                                <td className="dataCell1">
                                                    <div className="d-flex justify-content-center flex-row">
                                                        <Link
                                                            to={{
                                                                pathname: "/vendors/ticket/details",
                                                                state: {
                                                                    editItem: item
                                                                }
                                                            }}
                                                            className="button-outlined text-decoration-none"
                                                        >
                                                            View details
                                                        </Link>
                                                        {
                                                            item.ticketVendorAllocationStatus !== 'ALLOCATED' ?
                                                                <div>
                                                                    <span className="mx-1" />
                                                                    <button
                                                                        id="editBtn"
                                                                        className="button-outlined"
                                                                        onClick={() => this.handleApproval(item.code)}
                                                                    >
                                                                        Approve
                                                                    </button>
                                                                    <span className="mx-1" />
                                                                    <button
                                                                        id='delBtn'
                                                                        className="button-outlined"
                                                                    // onClick={() => this.handleDelete(item)}
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                </div>
                                                                : ''
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}