import Add from "./Add";
import React from "react";
import axios from "axios";
import Edit from "./Edit";
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationModal from "../../utils/ConfirmationModal";
import { ConfirmContext } from "../../utils/ConfirmationProvider";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Tooltip } from "react-tooltip";

export default class List extends React.Component {
    static contextType = ConfirmContext;
    state = {
        data: {
            brandName: '',
            website: '',
            description: '',
            brandMasterCode: '',
            isActive: true
        },

        // Handling modals
        showAsset: false,
        showAdd: false,
        showEdit: false,

        assetData: [],
        brandList: [],
        brandMasterMiniDTOList: [],

        // Handling Pagination 
        totalCount: 0,
        currentPage: 0,
        perPage: 10
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            }
        })
    }

    showAddModal = (isAdding) => {
        this.setState({
            ...this.state,
            data: isAdding ? this.state.data : {
                ...this.state.data,
                brandName: '',
                website: '',
                description: '',
            },
            showAdd: isAdding
        });
    }

    showEditModal = (item, isEditing) => {
        this.setState({
            ...this.state,
            data: item !== '' ? item : {
                ...this.state.data,
                brandName: '',
                website: '',
                description: ''
            },
            showEdit: isEditing
        });
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            currentPage: page - 1
        }, () => {
            this.getBrandList();
        })
    }

    getBrandList() {
        const data = {
            page: this.state.currentPage,
            size: this.state.perPage,
            typeOfRequest: "ALL"
        }
        axios({
            url: '/api/asset-master/list/brandmaster',
            data: data,
            method: 'post',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        brandList: data.data.brandMasterList,
                        totalCount: data.data.totalCount,
                        otpGenerated: true
                    })
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            })
    }

    showAssets = (code, showAsset, brandName) => {
        if (code) {
            axios({
                url: '/api/asset-master/list/assetmaster',
                data: {
                    page: "0",
                    size: "10",
                    brandCode: code,
                    typeOfRequest: "ALL"
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            showAsset: showAsset,
                            brandName: brandName,
                            assetList: data.data.assetMasterList
                        })
                    }
                })
        } else {
            this.setState({
                ...this.state,
                showAsset: showAsset
            })
        }
    }

    async handleDelete(item) {
        const { brandMasterCode, brandName, description, website } = item;
        const { getConfirmation } = this.context;
        const confirmed = await getConfirmation({
            message: 'Do you really want to delete?'
        });
        if (confirmed) {
            axios({
                url: '/api/asset-master/update/brandmaster',
                data: {
                    brandMasterCode: brandMasterCode,
                    brandName: brandName,
                    description: description,
                    website: website,
                    isActive: false
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: this.state.data,
                            currentPage: this.state.brandList.length === 1 ? this.state.currentPage - 1 : this.state.currentPage
                        }, () => {
                            this.getBrandList();
                            toast.success('Brand deleted sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                });
        }
    }

    handleSubmit = (e, type) => {
        e.preventDefault();
        if (type === 'add') {
            const { brandName, website, description } = this.state.data;
            axios({
                url: '/api/asset-master/add/brandmaster',
                data: {
                    brandName: brandName,
                    website: website,
                    description: description
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                brandName: '',
                                website: '',
                                description: '',
                                brandMasterCode: '',
                                isActive: true
                            },
                            showAdd: false
                        }, () => {
                            this.getBrandList();
                            toast.success('Brand added sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        } else {
            axios({
                url: '/api/asset-master/update/brandmaster',
                data: this.state.data,
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                brandName: '',
                                website: '',
                                description: '',
                                brandMasterCode: '',
                                isActive: true
                            },
                            showEdit: false
                        }, () => {
                            this.getBrandList();
                            toast.success('Brand updated sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                });
        }
    }

    componentDidMount() {
        this.getBrandList();
    }

    render() {
        const props = {
            ...this.state,
            showAssets: this.showAssets.bind(this),
            showAddModal: this.showAddModal.bind(this),
            handleChange: this.handleChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this),
            handleDelete: this.handleDelete.bind(this),
            showEditModal: this.showEditModal.bind(this)
        }
        return (
            <div>
                <ConfirmationModal />
                <ToastContainer />
                <Add {...props} />
                <Edit {...props} />
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h4 className="m-0">Brands List</h4>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button
                                    id="addBtn"
                                    className="button-solid"
                                    onClick={() => this.showAddModal(true)}
                                >
                                    Add Brand
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                                <thead>
                                    <tr>
                                        <th className="headerCell1" >ID</th>
                                        <th className="headerCell1" >Name</th>
                                        <th className="headerCell1" >Website</th>
                                        <th className="headerCell1" >Description</th>
                                        <th className="headerCell1" >Assets</th>
                                        <th className="headerCell1" >Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.brandList && this.state.brandList.map((item, key) => {
                                            return item.isActive ?
                                                (
                                                    <tr key={key}>
                                                        <th className="headerCell1" scope="row">{(this.state.currentPage * this.state.perPage) + (key + 1)}</th>
                                                        <td className="dataCell1">{item.brandName}</td>
                                                        <td className="dataCell1" style={{ wordBreak: 'break-word' }}>{item.website}</td>
                                                        <td className="dataCell1" style={{ wordBreak: 'break-word' }}>{item.description}</td>
                                                        <td className="dataCell1">
                                                            <Link
                                                                to={{
                                                                    pathname: '/brands/assets',
                                                                    state: { brandMasterCode: item.brandMasterCode }
                                                                }}
                                                                className="text-decoration-none"
                                                            >
                                                                View assets
                                                            </Link>
                                                        </td>
                                                        <td className="dataCell1">
                                                            <div className=" d-flex">
                                                                <button
                                                                    id="editBtn"
                                                                    className="border-0"
                                                                    style={{ background: 'none' }}
                                                                    onClick={() => this.showEditModal(item, true)}
                                                                    data-tooltip-id="editBtn"
                                                                    data-tooltip-content="Edit"
                                                                    data-tooltip-place="top"
                                                                >
                                                                    <img src="/dist/images/edit.svg" alt="editIcon" width={20} />
                                                                </button>
                                                                <Tooltip id='editBtn' />
                                                                <span className="mx-2" />
                                                                <button
                                                                    id='delBtn'
                                                                    className="border-0"
                                                                    style={{ background: 'none' }}
                                                                    onClick={() => this.handleDelete(item)}
                                                                    data-tooltip-id="delBtn"
                                                                    data-tooltip-content="Delete"
                                                                    data-tooltip-place="top"
                                                                >
                                                                    <img src="/dist/images/delete.svg" alt="delIcon" width={20} />
                                                                </button>
                                                                <Tooltip id="delBtn" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : ""
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end mt-2">
                                <PaginationControl
                                    // between={1}
                                    limit={this.state.perPage}
                                    total={this.state.totalCount}
                                    page={this.state.currentPage + 1}
                                    changePage={(page) => this.handlePageChange(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
};