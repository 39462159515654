import React from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../Modal";

export default class Add extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showAdd}>
                <ModalHeader>
                    <h4>Add Admin</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showModal(false, 'Add')}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <form style={{ width: 500 }}>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="firstName">
                                First Name
                            </label>
                            <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                className="form-control"
                                value={this.props.data.firstName}
                                placeholder="Enter first name"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="lastName">
                                    Last Name
                                </label>
                            </div>
                            <input
                                id="lastName"
                                name="lastName"
                                type="url"
                                className="form-control"
                                value={this.props.data.lastName}
                                placeholder="Enter last Name"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="countryCode">
                                    Country Code
                                </label>
                            </div>
                            <input
                                id="countryCode"
                                name="countryCode"
                                type="text"
                                className="form-control"
                                value={this.props.data.countryCode}
                                placeholder="Enter address line 1"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="phone">
                                Phone
                            </label>
                            <input
                                id="phone"
                                name="phone"
                                type="text"
                                className="form-control"
                                value={this.props.data.phone}
                                placeholder="Enter address line 2"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="email">
                                    Email
                                </label>
                            </div>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                className="form-control"
                                value={this.props.data.email}
                                placeholder="Enter email"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="outletCode">
                                    Outlet Code
                                </label>
                            </div>
                            <input
                                id="outletCode"
                                name="outletCode"
                                type="text"
                                className="form-control"
                                value={this.props.data.outletCode}
                                placeholder="Enter outlet code"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="vendorCode">
                                Vendor Code
                            </label>
                            <input
                                id="vendorCode"
                                name="vendorCode"
                                type="text"
                                className="form-control"
                                value={this.props.data.vendorCode || ''}
                                placeholder="Enter vendor code"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="userAccountType">
                                    User Account Type
                                </label>
                            </div>
                            <input
                                id="userAccountType"
                                name="userAccountType"
                                type="text"
                                className="form-control"
                                value={this.props.data.userAccountType}
                                placeholder="Enter user account type"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="userRole">
                                    User Role
                                </label>
                            </div>
                            <input
                                id="userRole"
                                name="userRole"
                                type="text"
                                className="form-control"
                                value={this.props.data.userRole}
                                placeholder="Enter User Role"
                                onChange={e => this.props.handleChange(e, 'data')}
                            />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="button-outlined"
                        onClick={(e) => this.props.handleSubmit(e, 'add')}>
                        Submit
                    </button>
                </ModalFooter>
            </Modal >
        )
    }
}