import React from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../Modal";

export default class Add extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showAdd}>
                <ModalHeader>
                    <h4 className="m-0">Add Vendor</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showAddModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <form style={{ width: 500, maxWidth: '100%' }}>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="vendorName">
                                Vendor Name
                            </label>
                            <input
                                id="vendorName"
                                name="vendorName"
                                type="text"
                                className="form-control"
                                value={this.props.data.vendorName}
                                placeholder="Enter vendor name"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="domainName">
                                    Domain Name
                                </label>
                            </div>
                            <input
                                id="domainName"
                                name="domainName"
                                type="text"
                                className="form-control"
                                value={this.props.data.domainName}
                                placeholder="Enter domain name"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="defaultCountryCode">
                                Default Country Code
                            </label>
                            <input
                                id="defaultCountryCode"
                                name="defaultCountryCode"
                                type="text"
                                className="form-control"
                                value={this.props.data.defaultCountryCode}
                                placeholder="Enter default country code"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="defaultPhone">
                                    Default Phone
                                </label>
                            </div>
                            <input
                                id="defaultPhone"
                                name="defaultPhone"
                                type="text"
                                className="form-control"
                                value={this.props.data.defaultPhone}
                                placeholder="Enter default phone"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="addressLine1">
                                    Address Line 1
                                </label>
                            </div>
                            <input
                                id="addressLine1"
                                name="addressLine1"
                                type="text"
                                className="form-control"
                                value={this.props.data.addressLine1}
                                placeholder="Enter address line 1"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="addressLine2">
                                Address Line 2
                            </label>
                            <input
                                id="addressLine2"
                                name="addressLine2"
                                type="text"
                                className="form-control"
                                value={this.props.data.addressLine2}
                                placeholder="Enter address line 2"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="city">
                                    City
                                </label>
                            </div>
                            <input
                                id="city"
                                name="city"
                                type="text"
                                className="form-control"
                                value={this.props.data.city}
                                placeholder="Enter city"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="zipCode">
                                    Zip Code
                                </label>
                            </div>
                            <input
                                id="zipCode"
                                name="zipCode"
                                type="text"
                                className="form-control"
                                value={this.props.data.zipCode}
                                placeholder="Enter zip code"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="stateCode">
                                State Code
                            </label>
                            <input
                                id="stateCode"
                                name="stateCode"
                                type="text"
                                className="form-control"
                                value={this.props.data.stateCode}
                                placeholder="Enter state code"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="latitude">
                                    Latitude
                                </label>
                            </div>
                            <input
                                id="latitude"
                                name="latitude"
                                type="text"
                                className="form-control"
                                value={this.props.data.latitude}
                                placeholder="Enter latitude"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="longitude">
                                    Longitude
                                </label>
                            </div>
                            <input
                                id="longitude"
                                name="longitude"
                                type="text"
                                className="form-control"
                                value={this.props.data.longitude}
                                placeholder="Enter longitude"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="legalName">
                                Legal Name
                            </label>
                            <input
                                id="legalName"
                                name="legalName"
                                type="text"
                                className="form-control"
                                value={this.props.data.legalName}
                                placeholder="Enter legal name"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="servicableMiles">
                                    Servicable Miles
                                </label>
                            </div>
                            <input
                                id="servicableMiles"
                                name="servicableMiles"
                                type="text"
                                className="form-control"
                                value={this.props.data.servicableMiles}
                                placeholder="Enter servicable miles"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="button-outlined"
                        onClick={(e) => this.props.handleSubmit(e, 'add')}>
                        Submit
                    </button>
                </ModalFooter>
            </Modal >
        )
    }
}