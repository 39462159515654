import React from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../Modal";

export default class Add extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showAdd}>
                <ModalHeader>
                    <h4 className="m-0">Add Brand</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showAddModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <form style={{ width: 500 }}>
                        <div className="form-group required mb-4">
                            <label className="control-label" mb="5px" htmlFor="brandName">
                                Name
                            </label>
                            <input
                                id="brandName"
                                name="brandName"
                                type="text"
                                className="form-control"
                                value={this.props.data.brandName}
                                placeholder="Enter brand name"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="website">
                                    Website
                                </label>
                            </div>
                            <input
                                id="website"
                                name="website"
                                type="url"
                                className="form-control"
                                value={this.props.data.website}
                                placeholder="Enter brand website"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                        <div className="form-group required mb-4">
                            <div className="d-flex">
                                <label className="control-label" mb="5px" htmlFor="description">
                                    Description
                                </label>
                            </div>
                            <textarea
                                id="description"
                                name="description"
                                type="text"
                                className="form-control"
                                value={this.props.data.description}
                                placeholder="Enter brand description"
                                onChange={e => this.props.handleChange(e)}
                            />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="button-outlined"
                        onClick={(e) => this.props.handleSubmit(e, 'add')}
                    >
                        Submit
                    </button>
                </ModalFooter>
            </Modal >
        )
    }
}