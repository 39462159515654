import React from "react";

export const ModalHeader = (props) => {
    return <div className="modal-header">{props.children}</div>;
}

export const ModalBody = (props) => {
    return <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>{props.children}</div>;
}

export const ModalFooter = (props) => {
    return <div className="modal-footer">{props.children}</div>;
}

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: '',
            display: 'none'
        }
    }

    openModal = () => {
        this.setState({
            modalShow: 'show',
            display: 'block'
        })
    }

    closeModal = () => {
        this.setState({
            modalShow: '',
            display: 'none'
        })
    }

    componentDidMount() {
        this.props.isOpen ? this.openModal() : this.closeModal();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.props.isOpen ? this.openModal() : this.closeModal();
        }
    }

    render() {
        return (
            <div
                className={'modal fade' + this.state.modalShow}
                role='dialog'
                style={{ display: this.state.display, backgroundColor: 'rgba(0,0,0,0.5)' }}
            >
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 'fit-content' }} role='document'>
                    <div className="modal-content">
                        {this.props.children}
                    </div>
                </div>
            </div >
        )
    }
}