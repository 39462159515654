import Add from "./Add";
import React from "react";
import axios from "axios";
import Edit from "./Edit";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../../utils/ConfirmationModal";
import { ConfirmContext } from "../../utils/ConfirmationProvider";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Tooltip } from "react-tooltip";

export default class List extends React.Component {
    static contextType = ConfirmContext;
    state = {
        data: {
            vendorName: "",
            domainName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            zipCode: "",
            stateCode: "",
            latitude: '',
            longitude: '',
            legalName: "",
            servicableMiles: '',
            defaultCountryCode: '91',
            defaultPhone: '9999996897',
            isActive: true,
            vendorCode: ''
        },

        // Handling modals
        showAsset: false,
        showAdd: false,
        showEdit: false,

        vendorList: [],
        searchQuery: '',

        // Handling Pagination 
        totalCount: 0,
        currentPage: 0,
        perPage: 10
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            }
        })
    }

    showAddModal = (isAdding) => {
        this.setState({
            ...this.state,
            showAdd: isAdding
        });
    }

    showEditModal = (item, isEditing) => {
        this.setState({
            ...this.state,
            data: item,
            showEdit: isEditing
        });
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            currentPage: page - 1
        }, () => {
            this.getVendorList();
        })
    }

    async handleDelete(item) {
        const { getConfirmation } = this.context;
        const confirmed = await getConfirmation({
            message: 'Do you really want to delete?'
        });
        if (confirmed) {
            axios({
                url: '/api/vendor/update',
                data: {
                    ...item,
                    isActive: false
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            }).then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        data: this.state.data,
                        currentPage: this.state.vendorList.length === 1 ? this.state.currentPage - 1 : this.state.currentPage
                    }, () => {
                        this.getVendorList();
                        toast.success('Vendor deleted sucessfully.', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                        });
                    })
                }
            });
        }
    }

    handleSubmit = (e, type) => {
        e.preventDefault();
        if (type === 'add') {
            const {
                vendorName, domainName, addressLine1, addressLine2,
                city, zipCode, stateCode, latitude, longitude, legalName,
                servicableMiles, defaultCountryCode, defaultPhone
            } = this.state.data;
            axios({
                url: '/api/vendor/add',
                data: {
                    vendorName: vendorName,
                    domainName: domainName,
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    city: city,
                    zipCode: zipCode,
                    stateCode: stateCode,
                    latitude: latitude,
                    longitude: longitude,
                    legalName: legalName,
                    servicableMiles: servicableMiles,
                    defaultCountryCode: defaultCountryCode,
                    defaultPhone: defaultPhone
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            }).then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            vendorName: "",
                            domainName: "",
                            addressLine1: "",
                            addressLine2: "",
                            city: "",
                            zipCode: "",
                            stateCode: "",
                            latitude: '',
                            longitude: '',
                            legalName: "",
                            servicableMiles: ''
                        },
                        showAdd: false
                    }, () => {
                        this.getVendorList();
                        toast.success('Vendor added sucessfully.', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                        });
                    })
                }
            })
        } else {
            axios({
                url: '/api/vendor/update',
                data: this.state.data,
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                vendorName: "",
                                domainName: "",
                                addressLine1: "",
                                addressLine2: "",
                                city: "",
                                zipCode: "",
                                stateCode: "",
                                latitude: '',
                                longitude: '',
                                legalName: "",
                                servicableMiles: ''
                            },
                            showEdit: false
                        }, () => {
                            this.getVendorList();
                            toast.success('Vendor updated sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                });
        }
    }

    getVendorList() {
        axios({
            url: `/api/vendor/list?isActive=true&page=${this.state.currentPage}&size=${this.state.perPage}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        vendorList: data.data.vendorList,
                        totalCount: data.data.totalCount
                    })
                } else {
                    toast.error(data.responseMessage, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            })
    }

    componentDidMount() {
        this.getVendorList();
    }

    handleFilterInput = (e) => {
        const { value, type } = e.target;
        if (type === 'reset') {
            this.setState({
                ...this.state,
                searchQuery: ""
            }, () => {
                this.getVendorList();
            });
        } else {
            this.setState({
                ...this.state,
                searchQuery: value
            });
        }
    }

    handleFilterSearch = () => {
        axios({
            url: `/api/vendor/search?isActive=true&page=0&size=10&searchQuery=${this.state.searchQuery}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        }).then((response) => {
            let data = response.data;
            if (data.responseCode === '1000') {
                this.setState({
                    ...this.state,
                    vendorList: data.data.vendorList,
                    totalCount: data.data.totalCount
                })
            }
        });
    }
    render() {
        const props = {
            ...this.state,
            showAddModal: this.showAddModal.bind(this),
            handleChange: this.handleChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this),
            showEditModal: this.showEditModal.bind(this)
        }
        return (
            <div>
                <ConfirmationModal />
                <ToastContainer />
                <Add {...props} />
                <Edit {...props} />
                <div className="p-2">
                    <div className="card">
                        <div className="card-header row mx-0">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h4 className="m-0">Vendors List</h4>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button
                                    className="mx-2 button-solid"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                    aria-controls="offcanvasRight">
                                    Filter
                                </button>

                                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                                    <div className="offcanvas-header">
                                        <h5 className="offcanvas-title" id="offcanvasRightLabel">Filter</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className="offcanvas-body">
                                        <div className="form-group required mb-4">
                                            <input
                                                id="search"
                                                name="search"
                                                type="text"
                                                className="form-control"
                                                value={this.state.searchQuery}
                                                placeholder="Search by name"
                                                onChange={this.handleFilterInput}
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-center">
                                            <button
                                                type="submit"
                                                data-bs-dismiss="offcanvas"
                                                className="button-outlined"
                                                onClick={this.handleFilterSearch}
                                            >
                                                Search
                                            </button>
                                            <span className="mx-2" />
                                            <button
                                                type="reset"
                                                className="button-outlined"
                                                data-bs-dismiss="offcanvas"
                                                onClick={this.handleFilterInput}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    id="addBtn"
                                    className="button-solid"
                                    onClick={() => this.showAddModal(true)}
                                >
                                    Add Vendor
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                                <thead>
                                    <tr>
                                        <th className="headerCell1">ID</th>
                                        <th className="headerCell1">Vendor Name</th>
                                        <th className="headerCell1">Domain Name</th>
                                        <th className="headerCell1">Default Country Code</th>
                                        <th className="headerCell1">Default Phone</th>
                                        <th className="headerCell1">Address Line 1</th>
                                        <th className="headerCell1">Address Line 2</th>
                                        <th className="headerCell1">City</th>
                                        <th className="headerCell1">Zip Code</th>
                                        <th className="headerCell1">State Code</th>
                                        <th className="headerCell1">Latitude</th>
                                        <th className="headerCell1">Longitude</th>
                                        <th className="headerCell1">Servicable Miles</th>
                                        <th className="headerCell1">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.vendorList && this.state.vendorList.map((item, key) => {
                                            return item.isActive ?
                                                (
                                                    <tr key={key}>
                                                        <th className="headerCell1" scope="row">{(this.state.currentPage * this.state.perPage) + (key + 1)}</th>
                                                        <td className="dataCell1">{item.vendorName}</td>
                                                        <td className="dataCell1">{item.domainName}</td>
                                                        <td className="dataCell1">{item.defaultCountryCode}</td>
                                                        <td className="dataCell1">{item.defaultPhone}</td>
                                                        <td className="dataCell1">{item.addressLine1}</td>
                                                        <td className="dataCell1">{item.addressLine2}</td>
                                                        <td className="dataCell1">{item.city}</td>
                                                        <td className="dataCell1">{item.zipCode}</td>
                                                        <td className="dataCell1">{item.stateCode}</td>
                                                        <td className="dataCell1">{item.latitude}</td>
                                                        <td className="dataCell1">{item.longitude}</td>
                                                        <td className="dataCell1">{item.servicableMiles}</td>
                                                        <td className="dataCell1">
                                                            <div className="d-flex justify-content-center">
                                                                <Link
                                                                    to={{
                                                                        pathname: "/vendors/tickets",
                                                                        state: {
                                                                            editItem: item
                                                                        }
                                                                    }}
                                                                    className="text-decoration-none"
                                                                    data-tooltip-id="requests"
                                                                    data-tooltip-content="View Ticket Requests"
                                                                    data-tooltip-place="top"
                                                                >
                                                                    <img src="/dist/images/ticketRequest.png" alt="requests" width={20} />
                                                                </Link>
                                                                <Tooltip id='requests' />
                                                                <button
                                                                    id="editBtn"
                                                                    className="border-0"
                                                                    style={{ background: 'none', padding: '0px 1px 0px 2px' }}
                                                                    onClick={() => this.showEditModal(item, true)}
                                                                    data-tooltip-id="editBtn"
                                                                    data-tooltip-content="Edit"
                                                                    data-tooltip-place="top"
                                                                >
                                                                    <img src="/dist/images/edit.svg" alt="editIcon" width={20} />
                                                                </button>
                                                                <Tooltip id='editBtn' />
                                                                <button
                                                                    id='delBtn'
                                                                    className="border-0"
                                                                    style={{ background: 'none', padding: '0px 1px 0px 2px' }}
                                                                    onClick={() => this.handleDelete(item)}
                                                                    data-tooltip-id="delBtn"
                                                                    data-tooltip-content="Delete"
                                                                    data-tooltip-place="top"
                                                                >
                                                                    <img src="/dist/images/delete.svg" alt="delIcon" width={20} />
                                                                </button>
                                                                <Tooltip id="delBtn" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : ''
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end mt-2">
                                <PaginationControl
                                    limit={this.state.perPage}
                                    total={this.state.totalCount}
                                    page={this.state.currentPage + 1}
                                    changePage={(page) => this.handlePageChange(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
};