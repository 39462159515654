import React from "react";
import Modal, { ModalBody, ModalHeader } from "../Modal";

export default class Brochure extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.showBrochure}>
                <ModalHeader>
                    <h4 className="m-0">Brochures</h4>
                    <span type="button" className="close" aria-label="Close" onClick={() => this.props.showModal(false, 'Brochure')}>
                        <span aria-hidden="true">&times;</span>
                    </span>
                </ModalHeader>
                <ModalBody>
                    <table className="w-100" style={{ borderSpacing: 2, borderCollapse: "separate" }}>
                        <thead>
                            <tr>
                                <th className="headerCell1">ID</th>
                                <th className="headerCell1" scope="col">Link</th>
                                <th className="headerCell1" scope="col">Content</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.brochures && this.props.brochures.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <th className="headerCell1" scope="row">{key + 1}</th>
                                            <td className="dataCell1"><a href={item.link}>{item.link}</a></td>
                                            <td className="dataCell1">{item.content}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </ModalBody>
            </Modal >
        )
    }
}