import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Add from "../Teams/Add";
import MarkComplete from "./MarkComplete";

export default class TcktDetails extends React.Component {
    state = {
        editItem: {},
        ticketDetails: {},
        selectedTeam: '',
        allocatedTeam: {},
        data: {
            name: '',
            phone: '',
            countryCode: '',
            isActive: true
        },
        closureData: {
            closureDescription: "",
        },
        filesData: {},
        showAdd: false,
        showComplete: false
    }

    handleApproval(code) {
        if (this.state.selectedTeam) {
            axios({
                url: `/api/ticket/opn/request-approval?code=${code}&teamUniqueCode=${this.state.selectedTeam}`,
                method: 'get',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            allocatedTeam: data.data
                        }, () => {
                            this.getTicketDetails();
                            toast.success('Ticket approved and allocated sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        });
                    } else {
                        toast.error(data.responseMessage, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                });
        } else {
            toast.error('Please select a team from existing ones or create a new one!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    getTicketDetails() {
        const { editItem } = this.state;
        const tktCode = window.location.pathname.split('/')[3];
        axios({
            url: `/api/ticket/opn/allocation-code?ticketAllocationCode=${editItem ? editItem.code : tktCode}`,
            method: 'get',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        }).then((response) => {
            let data = response.data;
            if (data.responseCode === '1000') {
                this.setState({
                    ...this.state,
                    ticketDetails: data.data,
                }, () => {
                    localStorage.setItem('vendorCode', data.data.vendorCode);
                })
            } else {
                toast.error(data.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        })
    }

    handleTeamSelect(code) {
        this.setState({
            ...this.state,
            selectedTeam: code
        })
    }

    handleAddFile(e) {
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append('file', e.target.files[i]);
        }
        formData.append('multimediaCategory', 'Ticket_Closure');
        formData.append('multimediaType', 'IMAGE');
        this.setState({
            ...this.state,
            filesData: formData
        })
    }

    markComplete(data) {
        const { filePath } = data;
        axios({
            url: '/api/ticket/opn/complete',
            data: {
                ticketAllocationCode: this.state.ticketDetails.ticketVendorAllocationCode,
                closureDescription: this.state.closureData.closureDescription,
                closureFiles: [
                    { "path": `${filePath}`, "multimediaType": "IMAGE" }
                ]
            },
            method: 'post',
            withCredentials: true,
            proxy: {
                host: "https://devapi.wellequipped.app"
            }
        })
            .then((response) => {
                let data = response.data;
                if (data.responseCode === '1000') {
                    this.setState({
                        ...this.state,
                        showComplete: false
                    }, () => {
                        this.getTicketDetails();
                        toast.success('Ticket marked completed sucessfully.', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                        });
                    })
                }
            })
    }

    handleSubmit = (e, type) => {
        e.preventDefault();
        if (type === 'completeData') {
            axios({
                url: '/api/ticket/opn/generic/upload',
                data: this.state.filesData,
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            ...data.data,
                        }, () => {
                            this.markComplete(data.data);
                        })
                    }
                })
        } else {
            const vendorCode = localStorage.getItem('vendorCode');
            axios({
                url: '/api/vendor/opn/team/add',
                data: {
                    ...this.state.data,
                    vendorCode: vendorCode
                },
                method: 'post',
                withCredentials: true,
                proxy: {
                    host: "https://devapi.wellequipped.app"
                }
            })
                .then((response) => {
                    let data = response.data;
                    if (data.responseCode === '1000') {
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                name: '',
                                phone: '',
                                countryCode: ''
                            },
                            showAdd: false
                        }, () => {
                            this.getTicketDetails();
                            toast.success('Team added sucessfully.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "colored",
                            });
                        })
                    }
                })
        }
    }

    showModal = (val, name) => {
        this.setState({
            ...this.state,
            [`show${name}`]: val,
            data: val ? this.state.data : {
                ...this.state.data,
                name: '',
                phone: '',
                countryCode: ''
            }
        })
    }

    handleChange(e, obj) {
        const { name, value } = e.target;

        this.setState({
            ...this.state,
            [obj]: {
                ...this.state[obj],
                [name]: value
            }
        })
    }

    componentDidMount() {
        const { editItem } = this.props.location.state || '';
        this.setState({
            editItem
        }, () => {
            this.getTicketDetails();
        });
    }

    render() {
        const { editItem, ticketDetails } = this.state;
        const vendorCode = localStorage.getItem('vendorCode');
        const props = {
            ...this.state,
            showModal: this.showModal.bind(this),
            handleChange: this.handleChange.bind(this),
            handleSubmit: this.handleSubmit.bind(this),
            handleAddFile: this.handleAddFile.bind(this)
        }
        return (
            <div>
                <Add {...props} />
                <ToastContainer />
                <MarkComplete {...props} />
                <Link to={{
                    pathname: `/vnd/tkt/vendorCode=${vendorCode}`,
                    state: {
                        editItem: editItem
                    }
                }} className='m-1 text-decoration-none'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                </Link>
                <div className="p-2">
                    <div className="card ">
                        <div id='ticketCardHeader' className="card-header d-flex flex-row align-items-center position-relative py-3">
                            <h4 className="m-0">Ticket Details</h4>
                            <div className="position-absolute end-0 pe-3">
                                {
                                    ticketDetails && ticketDetails.ticketStatus === "PENDING_VENDOR_ALLOCATION" ?
                                        <button
                                            id="approveBtn"
                                            className="button-solid"
                                            onClick={() => this.handleApproval(ticketDetails.ticketVendorAllocationCode, true)}
                                        >
                                            Approve & Allocate
                                        </button>
                                        : ''
                                }
                                {
                                    ticketDetails && ticketDetails.ticketStatus === "VENDOR_ALLOCATED" ?
                                        <button
                                            id="markComplete"
                                            className="button-solid"
                                            onClick={() => this.showModal(true, 'Complete')}
                                        >
                                            Ticket Closure
                                        </button>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                Object.keys(ticketDetails).length ?
                                    <div id='ticketDetails'>
                                        <div className="d-flex flex-row">
                                            <div className="details">
                                                Ticket #<span className="text-secondary">{editItem ? editItem.ticketCode : ticketDetails.ticketCode}</span><br />
                                                <p style={{ fontSize: 12, margin: 0 }}>Created on {(ticketDetails.ticket && ticketDetails.ticket.creationDate) || 'N/A'}</p>
                                                {
                                                    ticketDetails.ticket && ticketDetails.ticket.ticketStatus !== "VENDOR_ALLOCATED" ?
                                                        '' :
                                                        <p style={{ fontSize: 13 }}>The ticket has been approved.</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="details">
                                                <p className="text-uppercase my-1">description</p>
                                                {/* <p className="font1">{ticketDetails.ticket.description}</p> */}
                                            </div>
                                        </div>
                                        <div className="details">
                                            <p className="my-2">TICKET INFORMATION</p>
                                            {
                                                ticketDetails ?
                                                    <ul style={{ paddingInlineStart: 0, fontSize: 14 }}>
                                                        <li className="d-flex justify-content-sm-between mb-1" style={{ justifyContent: 'space-between' }}>
                                                            <span className="me-2">Ticket Name</span>
                                                            <span>{ticketDetails.ticketName}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-sm-between mb-1" style={{ justifyContent: 'space-between' }}>
                                                            <span>Vendor Name</span>
                                                            <span>{editItem ? editItem.vendorName : ticketDetails.vendorName}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-sm-between mb-1" style={{ justifyContent: 'space-between' }}>
                                                            <span>Asset Name</span>
                                                            <span>{ticketDetails.assetName || 'N/A'}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-sm-between mb-1" style={{ justifyContent: 'space-between' }}>
                                                            <span>Outlet Name</span>
                                                            <span>{ticketDetails.outletName}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-sm-between mb-1" style={{ justifyContent: 'space-between' }}>
                                                            <span>Outlet Address</span>
                                                            <span>{ticketDetails.outletAddress}</span>
                                                        </li>
                                                    </ul>
                                                    : 'N/A'
                                            }
                                        </div>
                                        <div className="details">
                                            <p className="my-2 text-uppercase">multimedia description</p>
                                            {
                                                ticketDetails && ticketDetails.multimediaDTOList ?
                                                    <ul style={{ paddingInlineStart: 0, fontSize: 14 }}>
                                                        {
                                                            ticketDetails.multimediaDTOList.map((item, key) => {
                                                                return <li key={key} className="d-flex mb-3 border-bottom">
                                                                    <div className="d-flex flex-column w-100">
                                                                        <div className="d-flex justify-content-sm-between" style={{ justifyContent: 'space-between' }}>
                                                                            <span>Path</span>
                                                                            <span>{item.path}</span>
                                                                        </div>
                                                                        <div className="d-flex justify-content-sm-between" style={{ justifyContent: 'space-between' }}>
                                                                            <span>Multimedia Type</span>
                                                                            <span>{item.multimediaType}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                    : <p className="font1">N/A</p>
                                            }
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="details">
                                                <div className="d-flex align-items-center">
                                                    <p className="me-2 my-2 text-uppercase">vendor teams</p>
                                                    <button
                                                        type="button"
                                                        className="button-outlined p-1 h-auto"
                                                        onClick={() => this.showModal(true, 'Add')}
                                                    >
                                                        Add Team
                                                    </button>
                                                </div>
                                                {
                                                    ticketDetails && ticketDetails.vendorTeams ?
                                                        <ul style={{ paddingInlineStart: 0, fontSize: 14 }}>
                                                            {
                                                                ticketDetails.vendorTeams.map((item, key) => {
                                                                    return <li key={key} className="d-flex align-items-center border-bottom">
                                                                        <input
                                                                            className="form-check-input me-2"
                                                                            type="radio"
                                                                            name="input1"
                                                                            id="input1"
                                                                            onChange={() => { this.handleTeamSelect(item.uniqueCode) }} />
                                                                        <div className="d-flex flex-column w-100">
                                                                            <span>{item.name}</span>
                                                                            <span>+{item.countryCode} {item.phone}</span>
                                                                        </div>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                        : <p className="font1">N/A</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}